import React from "react"
import { Modal } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import tw, { css } from "twin.macro"

const CloseIcon = () => (
  <CloseOutlined
    style={{
      fontSize: "12px",
      verticalAlign: "middle",
    }}
  />
)

interface CustomModalProps {
  isVisible: boolean
  width?: number
  title?: React.ReactNode
  onCancel?:
    | ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void)
    | undefined
  footer?: React.ReactNode
  children: any
}

const CustomModal = ({
  isVisible,
  title,
  onCancel,
  footer,
  width = 620,
  children,
}: CustomModalProps): React.ReactElement => (
  <Modal
    title={title}
    centered
    visible={isVisible}
    onCancel={onCancel}
    closeIcon={<CloseIcon />}
    footer={footer}
    width={width}
    css={css`
      & .ant-modal-content {
        border-radius: 20px;
      }
      & .ant-modal-header {
        padding: 16px 0px;
        border-radius: 20px 20px 0px 0px;
        background-color: #efefef;
        border-bottom: 1px solid #a0aec0;
      }
      & .ant-modal-close-x {
        height: 52px;
        line-height: 52px;
      }
      & .ant-modal-body {
        padding: 0px;
      }
      & .ant-modal-footer {
        padding: 0px;
        border-top: none;
        background-color: #efefef;
        border-radius: 0px 0px 20px 20px;
      }
    `}
  >
    {children}
  </Modal>
)

// eslint-disable-next-line react/display-name
CustomModal.Title = ({
  heading,
  children,
}: {
  heading: string
  children: any
}): React.ReactElement => (
  <div css={tw`flex justify-between px-16 items-center`}>
    <span css={tw`text-gray-900 text-lg font-bold`}>{heading}</span>
    {children}
  </div>
)

// eslint-disable-next-line react/display-name
CustomModal.Footer = ({
  heading,
  children,
  headingCss,
}: {
  heading: string
  children: any
  headingCss?: any
}): React.ReactElement => (
  <div tw="flex py-3 px-16 justify-between items-center">
    <div css={[tw`text-orange-1 text-xs font-bold`, headingCss]}>{heading}</div>
    {children}
  </div>
)

export default CustomModal
